.partial-underline {
  position: relative;
}

.partial-underline:after {
  content: "";
  height: 4px;
  left: 0;
  bottom: 0;
  position: absolute;
  background-color: #e81c25;
  width: 3rem;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.partial-underline:hover::after {
  width: 100%;
}

@media (hover: none) and (pointer: coarse) {
  .glide__arrows {
    display: none;
  }
}
